@font-face {
  font-family: "LatoBold";
  src: local("Lato"),url("./Assets/Font/LatoBold.ttf") format("truetype");
}

@font-face {
  font-family: "LatoMedium";
  src: local("Lato"),url("./Assets/Font/LatoMedium.ttf") format("truetype");
}

@font-face {
  font-family: "LatoRegular";
  src: local("Lato"),url("./Assets/Font/LatoRegular.ttf") format("truetype");
}

@font-face {
  font-family: "LatoSemibold";
  src: local("Lato"),url("./Assets/Font/LatoSemibold.ttf") format("truetype");
}

@font-face {
  font-family: "LatoLight";
  src: local("Lato"),url("./Assets/Font/LatoLight.ttf") format("truetype");
}

body {
  margin: 0;
  background: #3D4E79;
}
.ag-theme-alpine .ag-root-wrapper {
  border: solid 1px;
  /* border-color: #babfc7; */
  /* border-color: var(--ag-border-color, #babfc7); */
 }
*{
  -ms-overflow-style: none;
}
/* 
::-webkit-scrollbar{
    width:  8px;
    background: gray;
} */
.cantainer{
    /* style={{ maxWidth: "1000px", justifyContent: "center", margin: '50px' }}  */
    max-width: 1000px;
    justify-content: center;
    margin: 25px 20px 25px 100px;
    font-family: 'LatoRegular';
    
}
.cantainer h1{
    /* style={{ color: "#27ED82", textAlign: "center" }} */
    color: #27ED82;
    text-align: center;
}
.cantainer h3{
    /* style={{ color: "#27ED82", textAlign: "left" }} */
    color: #27ED82;
    text-align: left;
}
.cantainer h4{
    color: #27ED82;
    text-align: left;
}
.cantainer p{
    color: white;
    text-align: left;
}
.cantainer p a{
    color: blue;
}
.summarycard-outer { 
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}


.summary-card{ 
    background: #051C55;
    border-radius: 5px;
    font-family: 'LatoSemibold';
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.015em;
    text-transform: capitalize;
    color: white;
    width: calc( 100%/4 - 19px); 
    padding: 15px; 
    .summary-title{
        font-size: 22px;
        line-height: 22px;
        text-align: center;
    }
    .summary-amount{
        font-size: 2em;
        line-height: 54px;
        margin: 0;
        text-align: center;
    }
}
.summary-card + .summary-card {
    margin-left: 25px;
}



@media only screen and  (max-width: 1800px) and (min-width: 1600px) {    
    .summary-card .summary-amount {
        font-size: 2em;
        line-height: 0px;
        margin: 0;
        text-align: center;
        padding: 24px;
    }
}
@media only screen and  (max-width: 1600px) and (min-width: 1400px) {    
    .summary-card .summary-amount {
        font-size: 1.6em;
        line-height: 0px;
        margin: 0;
        text-align: center;
        padding: 24px;
    }
}
@media (max-width:1199px) { 
    .summary-card .summary-amount { font-size: 26px; }
    .summary-card .summary-title { margin: 10px 0; }
}

@media (max-width:991px) { 
    .summary-card .summary-title {
        font-size: 16px;
    }
    .summary-card .summary-amount{
        line-height: normal;
        font-size: 16px;
    }
    .summary-card + .summary-card{
        margin-left: 15px;
    }
    .summary-card{
        padding: 15px 10px;
    }
    .summarycard-outer{
        justify-content: space-between;
    }

 }

.navbar-container{
    // position: fixed;
    display: flex;
    background: #051C55;
    height: 66px;
    width: 100%;
    padding: 15px 0px 0px 0px;
    z-index: 888;    
    .navbar-logo-container { width: 10%; } 
    .navbar-search, .bell-icon  { display: none; } 
    .navbar-list {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 10px;
        .bell-icon {
            margin-left: 5% !important;
        }        
        .user-photo-name{
            display: flex;
            width: 20%; 
            justify-content: space-evenly;
            width: 297px;
            .user-photo {
                position: relative;
                img { 
                    border-radius: 50%;
                }
            }
            .user-pic { position: absolute; z-index: 1; bottom: 8px; right: 2px; }        
        }
    }
}
.navbar-zindex{
    z-index: -1
}

.status-table { 
    display: flex;
    justify-content: space-between; 
    width: 100%;
}

.composition-table-container{
    border: 1px solid #051C55;
    font-family: 'LatoMedium';
    font-style: normal;
    letter-spacing: 0.015em;
    text-transform: capitalize;
    margin: 70px 0 0;
    background-color: #051C55;
    width: 58%;
    .weight-list{
        display: flex;
        justify-content: space-evenly;
        h4 {
            color: #fff;
        }
    }
    .pie-chart{
        display: flex;
        width: 100%;
        color: #fff;
    }
    .composition-date{
        background-color: #DB55EB;
        width: 135px;
        height: 28px;
        border-color: #051C55;
        vertical-align: top;
    }
    .lockScreen img{
        width: 100%;
        height: 508px;
    }

}
@media(max-width:1199px){
    .status-table {
        // flex-wrap: wrap;
    }
    // .composition-table-container .pie-chart {
    //     flex-wrap: wrap;       
    // }
    // .composition-table-container .pie-chart > div {
    //     width: 100% !important;
    // }
    // .composition-table-container .pie-chart .sector {
    //     border-left: none !important;
    //     border-top:4px dotted rgb(30, 242, 242);
    // }

 }

@media(max-width:991px){ 
       
}


.delete-container{
    font-family: 'LatoLight';
    font-style: normal;
    font-weight: normal;

    .delete-quote{
        width: 62%;
        // margin: auto;
        font-size: 23px;
        line-height: 28px;
        text-transform: capitalize;
        color: #ffff;
        text-align: center;
        margin-left: 17%;
    }

    .delete-btn{
        display: flex;
        justify-content: space-evenly;
        margin-top: 80px;
        .cancel-btn{
            width: 109px;
            height: 38px;
            left: 117px;
            top: 102x;
            background: #696F73;
            border-radius: 2px;
            outline: none;
            border-color: #696F73;
            cursor: pointer;
        }
        .yes-btn{
            width: 109px;
            height: 38x;
            left: 245px;
            top: 102px;
            background:#94B3FC;
            border-radius: 2px;
            outline: none;
            color:#051C55;
            font-weight:600;
            border-color: #94B3FC;
            cursor: pointer;
        }
    }
}
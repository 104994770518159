.fundamentables-table-container{
    border: 1px solid #051C55;
    font-family: 'LatoLight';
    font-style: normal;
    letter-spacing: 0.015em;
    text-transform: capitalize;
    margin: 70px 0 0;
    width: 100%;
    background-color: #051C55;
    .fundamentables-table{
        // width: 1337px;
        // height: 490px;
         .ag-header-row-column-group{
            background-color: #051C55;
            color: #94B3FC
        }
        .ag-header-row-column{
            background-color:#051C55;
        }
        .ag-ltr{
            background-color: #051C55;
        }
        .ag-row{
            background-color: #051C55;
            color: white;
            border: none;
        }
        .ag-cell{
            width: 0px;
        }
        .ag-theme-alpine {
            padding: 0;
            box-sizing: inherit;
            font-family: 'LatoMedium';
            font-size: 15px !important;
            border: none;
            box-sizing: content-box;
            // height: 490px;
        }
        .ag-header-cell-text{
            color: #051C55;
            // margin-left: 20px;
            background-color: #94B3FC;
        }
        .ag-input-field-input{
            background-color: #4f72b0a1;
        }
        // .ag-header-group-text{
        //     // margin: auto;
        // }
        .ag-icon-grip{
            color: black;
        }
        // .ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group{
        //     // padding-right: 0px;
        // }
        .ag-header-group-cell-label, .ag-header-cell-label{
            overflow:initial;
        }
        // .ag-cell-label-container{
        //     // width: fit-content;
        // }
        // .ag-theme-alpine .ag-ltr .ag-row-drag, .ag-theme-alpine .ag-ltr .ag-selection-checkbox, .ag-theme-alpine .ag-ltr .ag-group-expanded, .ag-theme-alpine .ag-ltr .ag-group-contracted{
        //     // margin-right: 0px;
        // }
        // .ag-cell >.ag-cell-auto-height >.ag-cell-not-inline-editing >.ag-cell-focus>.ag-cell-wrapper>.ag-cell-value:nth-child(1){
        //     // padding-right: 9px;
        //     // left: 90px;
        // }
        .ag-header-row-column{
            // width: 1405px !important;
            background-color: #94B3FC;
        }
        .ag-header-row-column-group{
            // width: 1405px !important;
            background-color: #94B3FC;
        }
        // .ag-row-odd{
        //     // background-color: #161616;
        // }
        .transaction-type:nth-child(2){
            background: blanchedalmond;
        }
        input::-webkit-calendar-picker-indicator {
            cursor: pointer;
            padding: 0;
            margin: 0;
        }
        .ag-cell-value{
            overflow: hidden !important;
        }
    }
    .lockScreen img{
        width: 100%;
        height: 500px;
    }
//     overflow-y: scroll;
//     table th{
//         // position: sticky;
//         // top: 0;
//     }
//     table{
//         border-collapse: collapse;
//         border: none;
//         width: 100%;
//     }
//     table, th, td {
//         // border: 1px solid #212121;
//     }
//     table td:nth-child(1){
//         color: white;
//         font-size: 15px;
//         line-height: 18px; 
//         width: 2000px;
//         font-family: 'LatoMedium';
//     }
//     // table td:nth-child(2){
//     //     font-size: 14px;
//     //     line-height: 17px;
//     //     color: #20FFFF;
//     // }
//     table td{
//         font-size: 14px;
//         line-height: 17px;
//         color: #FFFFFF;
//         text-align: center;
//     }
//     table th {
//         background-color: #0D1013;
//         font-size: 18px;
//         line-height: 22px;
//         color: #27ED82;
//         height: 32px;
//         width: 1335px;
//     }
// }
// .fundamentables-table::-webkit-scrollbar{
//     width: 0px;
// }
// .fundamentables-table::-webkit-scrollbar-track{
//     background: chartreuse;
// }
// .fundamentables-table::-webkit-slider-thumb{
// }
// .fundamentables-table::-webkit-slider-thumb:hover{
//     background-color: yellow;
// }
}

@media only screen and  (max-width: 1600px) and (min-width: 1400px) {
    .fundamentables-table-container{
        // width: 1060px !important;
    }
    .fundamentables-table .ag-theme-alpine{
        // width: 1060px !important;
    }
}

@media only screen and  (max-width: 1800px) and (min-width: 1600px) {
    .fundamentables-table-container{
        // width: 1250px;
        // width: 560px !important;
    }
    .fundamentables-table .ag-theme-alpine{
        // width: 1250px !important;
        // width: 560px !important;
    }
}
.ibkr-container{
    padding: 20px;
    // border: 2px solid white;
    display: flex;
    font-size: 20px;
    font-family: "LatoRegular";
    flex-direction: column;
//    flex-direction: ;
    ::placeholder {
    color: white;
    }
    .dropdown{
        color:#94B3FC;
        background-color: #051C55;
        border-radius: 3px;
        padding: 5px;
        font-size: 16px;
        border: none;
        cursor: pointer;
        font-family: 'LatoRegular';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        width: 40%;
        height:33px;
        display: flex;
        justify-content: space-between;
    }
}
.ibkr-label-div{
    color: white;
    display: flex;
    flex-direction: column;
    line-height: 40px;
}
.ibkr-label-inline-div{
    display: flex;
    width: 100%;
    // justify-content: space-between;
}
.ibkr-content>div{
    line-height: 20px;
    margin:40px 0px ;
}
.ibkr-content p{
    font-size: 20px;
    // font-size: 15px;
}
.ibkr-btn{
    border: 0;
    padding: 10px 40px;
    background: #94B3FC;
    border-radius: 1px;
    outline: none;
    color:#051C55;
    border-color: #94B3FC;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    margin: 15px 0px;
    cursor: pointer;
}
.ibkr-btn + .ibkr-btn {
    margin-left: 10px;
}
.btn-div{
    display: inline-block;
    vertical-align: top;
}
.checkbox-div{
    margin-bottom: 10px;
}
.ibkr-checkbox{
    width: 16px;
    height: 16px;
    margin-right: 10px;
}
.ibkr-checkbox input[type='checkbox']:checked:after {
    background-color:#94B3FC !important;
}

.input-field{
        outline: none;
        //   padding: 14px 0;
        border-bottom: 3px solid #94B3FC;
          border-top: black;
          border-left: black;
          border-right: black;
          border-radius: 0px;         
          transition: box-shadow 0.2s ease 0s, border 0.2s ease 0s;
        //   width: 100%;
          background: rgba(0, 0, 0, 0);
          color: white;      
        //   height: 46px;
          font-weight: normal;      
          font-family: 'Roboto';
          font-size: 16px;
        //   border-bottom: 1px solid black;
        //   &:focus{
        //     border-bottom: 3px solid #27ED82;
        //     background: rgba(0, 0, 0, 0);
        //   }
          color:#94B3FC ;
}
.input-date{
    background-color: #DB55EB;
    border: 3px solid #DB55EB;
}
.count-icon{
    display: flex;
    // margin-right: 5px;
    .button{
        background: #94B3FC;
    }
}
.add-icon,.remove-icon{
    display: flex;
    align-items: center;
}
.count-icon >div >img{
    cursor: pointer;
    height: 25px;
}

.ibkr-label-inline-div > div > label{
    display: inline-block;
    vertical-align: top;
}
.ibkr-label-inline-div > div > label + label{
    margin-left: 10px;
}
@media(max-width: 1800px) {
    .ibkr-container{
        font-size: 16px;
    }
    .ibkr-content p{
        font-size: 16px;
    }
}

@media(max-width:1199px){
    .ibkr-label-inline-div > div > label + label {
        display: block;
        margin-left: 0px;
    }
 }
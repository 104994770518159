.current-plan-container{
    font-family: 'LatoRegular';
    // width: 90%;
    // margin-left:auto;
    // margin-right:auto;
    .current-plan{
        border-bottom: 1px solid #051C55;
        // width: 100%;
        padding: 15px 0px;
    }
    .current-Plan-Name{
        color:orange;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: bold;
    }
    
    .current-plan-details{
        display: flex;
        justify-content: space-between;
        // width: 100%;
    }
    
    .plan-button{
        display: flex;
        flex-direction: column;
        .initiate-plan, .update-plan, .cancel-plan{
            border: 0;
            padding: 10px 40px;
            background-color:#051C55;
            background:#051C55;
            border-radius: 5px;
            color: white;
            font-weight: bold;
            font-size: 16px;
            margin: 15px 0px;
            cursor: pointer;
        }
    }
    
}
.status-table { 
        
}

@media only screen and  (max-width: 1600px) and (min-width: 1400px) {
    
    .ag-cell-wrap-text{
        word-break: initial;
    }
}

.main-outer { 
    display: flex;
}
.main-outer .left-header-container {
    width: 281px;
    height: 100%;
    position: fixed;
    left: 0;
}
.main-outer .right-header-container { 
    width: calc(100% - 281px);
    margin-left: 281px;
}
.stoxdoc-part {
    padding-top: 50px;
    overflow-y: auto;
    // height: calc(100vh - 67px);
}
.inner-part { 
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 70px;
}
.right-header-container{ 
    .nav-bar{
        width: calc(100% - 281px);
        background: #02162e;
        position: fixed;
        z-index: 1;
    }
}

@media( max-width:1199px){ 
    .stoxdoc-part { padding-top: 30px; }     
}

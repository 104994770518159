@import "../../Constant/colors";

.add-transactions-modal-container{
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    // .create-portfolio-modal{

    //     .create-portfolio-modal-btn{
    //         width: 48px;
    //         height: 28px;
    //         background: linear-gradient(94.68deg, #59F1D8 -15.73%, #2AF270 92.38%);
    //         border-radius: 1px;
    //         outline: none;
    //         border-color: #2AF270;
    //         float: right;
    //         margin-bottom: 5px;
    //     }
    // }
    .add-transactions-modal-table{
        .add-transactions-table{
            border-collapse: collapse;
            width: 100%;
        }
        .add-transactions-table td, .add-transactions-table th {
            border: 1px solid #4F4F4F;
            padding: 8px;
        }
        .add-transactions-table{
            font-family: 'LatoLight';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.015em;
            text-transform: capitalize;
            color: #0DF983;
        }
    }
    .add-transactions-save{
        background: linear-gradient(114.24deg, #59F1D8 -7.59%, #2AF270 72.56%);
        opacity: 0.5;
        font-family: 'LatoLight';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.015em;
        text-transform: capitalize;
        color: $black;
        padding: 5px 5px;
        display: flex;
        margin: auto;
        width: 30%;
    }
    .add-transactions-table tbody td{
        color: $white;
        margin: auto;
    }
    .create-btn{
        .btn{
            margin-top: 10%;
            width: 150.83px;
            height: 32.09px;
            left: 978.17px;
            top: 349.17px;
            background: linear-gradient(94.68deg, #59F1D8 -15.73%, #2AF270 92.38%);
            border-radius: 1px;
            outline: none;
            border-color: $createBtn;
            float: right;
        }
    }
    .add-transaction-data{
        text-align: center;
    }
}
.edit-watchlist-modal-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    .edit-watchlist-modal-table{
        p{
            font-family: 'LatoLight';
            font-style: normal;
            font-weight: 300;
            font-size: 25px;
            line-height: 30px;
            letter-spacing: 0.015em;
            text-transform: capitalize;
            color: #ffff;
            margin: 35px 35px;
            padding-bottom: 30px;

        }
    }
    .create-btn{
        display: flex;
        .btn{
            height: 32px;
            width: 100px;
            background:#94B3FC;
            border-radius: 1px;
            outline: none;
            color:#051C55;
            font-weight:600;
            border-color: #94B3FC;
            margin-left: 5%;
        }
    }
}
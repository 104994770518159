.status-bar{
    position: relative;
    height: 3px;
    border-radius: 50px;
    background: #646464;
}

.filler{
    background: #1DA598;
    height: 100%;
    border-radius: inherit;
    transition: width .2s ease-in;
}
.user-name-dropbtn {
  background-color: #051C55;
  display: flex;
  font-family: 'LatoMedium';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;
  text-transform: capitalize;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
}

.user-dropdown {
  position: relative;
  display: inline-block;
  width: 127px;
}

.user-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.user-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-family: 'LatoMedium';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;
  text-transform: capitalize;
}

.user-dropdown-content a:hover {background-color: #f1f1f1}

.user-dropdown:hover .user-dropdown-content {
  display: block;
}

.user-dropdown:hover .user-name-dropbtn {
  background-color: #01172E;
}



@media (max-width:991px) {
.user-name-dropbtn {
  font-size: 16px;
}
.main-navigation ul > li.menu-item-has-children > a {
  width: auto;
  font-size: 14px;
}
.main-navigation ul > li.menu-item-has-children > a{
  padding: 0 7px;
}

}

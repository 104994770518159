.create-new-watchlist-container{
    margin-top: 5%;
    margin-left: 10px;
    margin-right: 10px;
    .create-new-watchlist-input{
        width: 100%;
        max-width: 464px;
        height: 46px;
        border: 0.5px solid #94B3FC;
        box-sizing: border-box;
        border-radius: 2px;

        font-family: 'LatoLight';
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0.015em;
        text-transform: capitalize;
        background: black;
        color: #77867A;
        padding: 10px 10px;
        display: flex;
        margin: auto;
    }

    .create-new-watchlist-btn{
        display: flex;
        justify-content: center;
        padding: 70px 100px;
        button{
            width: 92px;
            height: 33px;
            left: 749px;
            top: 254px;
            background: #94B3FC;
            border-radius: 1px;
            outline: none;
            color:#051C55;
            border-color: #94B3FC;
        }
    }
}
.navbar-search{
    margin-bottom: 15%;
    input[type=text] {
        background-image: url('../../Assets/Images/search.svg');
        background-repeat: no-repeat;
        background-position: right;
        width: 530px;
        height: 37px;
        background-color: #0F1F30;
        border: 0.5px solid #3C3C3C;
        border-radius: 1px;
        background-size: 28px;
        font-weight: 300;
        font-size: 18px;
        font-family: 'LatoLight';
        line-height: 22px;                                                                
        letter-spacing: 0.015em;
        text-transform: capitalize;
        color: #556473;
        outline: 0.5px #3C3C3C;                                                                                                                                                                             
        padding: 0px 5px;
    }
}


@media only screen and  (max-width: 1600px) and (min-width: 1400px) {
    .navbar-search{
        width: 25%;
        input[type=text]{
            width: 95%;
            margin-left: 5%
        }  
        
    }    
}

@media only screen and  (max-width: 1800px) and (min-width: 1600px) {
    .navbar-search{
        width: 30%;
        input[type=text]{
            width: 95%;
            margin-left: 5%
        }     
    } 
}


@media(max-width:1200px){
    .search-container input  {
        width: 100% !important;
    };
}

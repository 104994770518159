.name-route-list-container{
        display: flex;
        justify-content: space-between;
        width: 100%;
    
        .name-route-list{
        display: flex;
        align-items: center;
        font-family: 'LatoRegular';
        font-style: normal;
        font-weight: normal;
        letter-spacing: 0.015em;
        text-transform: capitalize;
        width: 100%;

        .list-name{
            // font-size: 14px;
            line-height: 17px;
            color: #FFFFFF;
        }
        .list-name-one{
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
        }
        .margin{
            margin-left: 10px;
        }
        .h-25{
            height: 25%;
        }
    }
    .name-route-list-create-action{
        display: flex;
        align-items: center;
        // margin-left: 82%;
    }
}
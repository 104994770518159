.brokerPortfolioLink{
    margin: 50px auto 0px;
    width: 100%;
    max-width:95%;
    padding: 0 10px;
    font-family: 'LatoRegular';
    .brokerRow{
        display: flex;
        justify-content: space-between;
        // margin:20px;
        margin-bottom: 20px;
    }
    .dropdown{
        color:#94B3FC;
        background-color: #051C55;
        border-radius: 3px;
        padding: 5px;
        font-size: 16px;
        border: none;
        cursor: pointer;
        font-family: 'LatoRegular';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        width: 40%;
        height:33px;
        display: flex;
        justify-content: space-between;
    }
    .multiSelectContainer{
        width: 50%;
       
        border-radius: 3px;
        color:#94B3FC;
        border: #051C55;
        background-color: #051C55;
    }
    .searchWrapper{
        border:none;
        overflow-x: scroll;
        display: flex;
        justify-content: right;
        text-align: right;
        .searchBox{
            width: 191px;
        }
    }
    .searchBox#css_custom_input::placeholder {
        color: white;
        font-weight: bold;
      }
    .broker-btn{
        // margin-top: 10%;
        width: 77.83px;
        height: 32.09px;
        left: 978.17px;
        top: 349.17px;
        background: #94B3FC;
        border-radius: 5px;
        outline: none;
        color:#051C55;
        border-color: #94B3FC;
        float: right;
        display: inline-block;
        vertical-align: top;
    }
    .broker-submit-button{
        margin-top: 60px;
    }
}
@media(max-width:991px){    
    .brokerPortfolioLink  .button-div > .broker-btn {
        margin-right: 10px;
        display: inline-block;
        vertical-align: top;
    }  
}

.search-container{
    padding-bottom: 5%;
    input {
        background: #051C55;
        border: none;
        border-bottom: 1px solid #FFFFFF;
        width: 338px;
        font-family: 'LatoLight';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.015em;
        text-transform: capitalize;
        color: #FFFFFF;
        outline: none;
        vertical-align: top;
    }
    ::placeholder{
        color:#ffffff
    }
}
.billing-history-container{
  font-family: 'LatoRegular';
  // width: 90%;
  // margin-left:auto;
  // margin-right:auto;
  .billing{
        padding: 15px 0px;
        border-bottom: 1px solid #051C55;
        // width: 100%;
        // padding: 15px 0px;
    }

    .billing-history{
        display: flex;
        flex-direction: column;
        // width: 100%;
    }

    .billing-table {
        font-family: 'LatoRegular';
        // border-collapse: collapse;
        // width: 100%;
        padding: 1% 0%;
      }
      
      .billing-table td, .billing-table th {
        // border: 1px solid black;
        // padding: 8px;
        width: 50%;
      }
}
/* ========= App Styles  ========= */
.my-app {
  /* width: 98vw; */
  height: 98vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ========= Grid Styles  ========= */

.ag-center-cols-container .MuiInput-underline:before {
  display: none !important;
}

.ag-status-bar {
  background: black;
  height: 60px;
}

.ag-input-field-input.ag-text-field-input {
  height: 40px;
  font-size: 1rem !important;
}

.transactionData-table-container{
   border: 1px solid #051C55;
  font-family: 'LatoMedium';
  font-style: normal;
  letter-spacing: 0.015em;
  text-transform: capitalize;
  margin: 70px 0 0 ;
  width: 100%;
}

.ag-header-row-column-group{
  background-color: #0D1013;
  color: #27ED82
}
.ag-header-row-column{
  background-color: black;
}
.ag-ltr{
  background-color: black;
}
.ag-row{
  background-color: black;
  color: white;
  border: none;
}
.ag-cell{
  width: 0px;
}
.ag-theme-alpine {
  padding: 0;
  box-sizing: inherit;
  font-family: 'LatoMedium';
  font-size: 15px !important;
  border: none;
  box-sizing: content-box;
  // height: 490px;
}
.ag-cell .ag-cell-not-inline-editing .ag-cell-auto-height{
  /* // left: 100px; */
}
.ag-header-cell-text{
  color: #ffffff;
  margin-left: 0px;
  // background-color: #0D1013;
}
.ag-input-field-input{
  background-color: white !important;
  color: black !important;
}
.ag-header-group-text{
  margin: auto;
}
.ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group{
  padding-right: 0px;
  /* // width: 166px !important; */
  /* // left: 80px !important; */
}
.ag-theme-alpine .ag-cell.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group:nth-child(1){
  /* // padding-right: 0px;
  // left: 81px !important;
  // width: 181px !important;
  // width: 166px !important;
  // left: 80px !important; */
}
.ag-header-group-cell-label, .ag-header-cell-label{
  overflow:initial;
}
.ag-cell-label-container{
  width: fit-content;
}
.ag-theme-alpine .ag-ltr .ag-row-drag, .ag-theme-alpine .ag-ltr .ag-selection-checkbox, .ag-theme-alpine .ag-ltr .ag-group-expanded, .ag-theme-alpine .ag-ltr .ag-group-contracted{
  /* // margin-right: 0px; */
}
.overflow-initial .ag-cell-label-container {
  overflow: initial;
}
.ag-cell >.ag-cell-auto-height >.ag-cell-not-inline-editing >.ag-cell-focus>.ag-cell-wrapper>.ag-cell-value:nth-child(1){
  padding-right: 9px;
  /* // left: 90px; */
}
.ag-header-row-column{
  // width: 1405px !important;
  background-color: #0D1013;
}
.ag-header-row-column-group{
  // width: 1405px !important;
  background-color: #0D1013;
}
.ag-row-odd{
  /* // background-color: #161616; */
}
.transaction-type:nth-child(2){
  background: blanchedalmond;
}
input::-webkit-calendar-picker-indicator {
  cursor: pointer;
  padding: 0;
  margin: 0;
}
.ag-cell-value{
  overflow: unset !important;
}
.ag-body-viewport {
  overflow-x: hidden;
}

@media(max-width:1200px){
  .ag-header-cell-text {
      margin-left: 0px;    
  }
}
.broker_table{
    // border:2px solid gray;
    margin-left: -5px;
    width: 102%;
    thead>tr>td{
        color: #94B3FC;
        font-size:20px;
        padding:10px 0px;
        font-family: 'LatoLight';
        text-align : center;
    }
    tbody>tr{
        color: white;
    }
    tbody>tr>td{
        padding-left: 4px;
        border:0.1px solid gray;
    }
    ul{
        padding-left: 0px;
        list-style-type:none;
    }
}
.button-div{
    float:right;
    width: 300px;
    display: flex;
    justify-content: space-between;
    margin-top:20px;
}
.tr-border{
    border:0.1px solid gray;
}
.efficient-date{
    background-color: #DB55EB;
    width: 135px;
    height: 28px;
    border-color: #051C55;
    vertical-align: top;
}
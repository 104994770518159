.login-container{
    margin: auto;
    // padding: 16px;
    background-position: center center;
    transition: transform 100s ease 0s;
    transform-origin: 50% 30%;
    transform: scale(1);
    background-size: cover;
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    &:before{
        position: absolute;
        display: block;
        content: "";
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        // background: rgba(20, 28, 37, 0.6);
      }
      .reg-form{
        // margin: auto;
        margin-top: 15px;
        position: relative;
        // width: 100%;
        width: 400px;
        padding: 40px;
        display: flex;
        flex-direction: column;         
        max-width: 380px;    
        background: #051C55;
        // border: 2px solid black;
        box-shadow: 0px 0px 15px 0px #ffffff;
        .fieldset{      
          position: relative;
          padding: 16px 0 0;
          margin: 0 0 24px;
          border: transparent;
        }
        .label{
          position: absolute;
          top: 0;
          left: 0;
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
        }
        .input{  
        //   @include outline(0);  
            outline: none;
          padding: 14px 0;
          border: transparent;
          border-radius: 0px;         
          transition: box-shadow 0.2s ease 0s, border 0.2s ease 0s;
          width: 100%;
          background: rgba(0, 0, 0, 0);
          color: white;      
          height: 46px;
          font-weight: normal;      
          font-family: 'Roboto';
          font-size: 16px;
          border-bottom: 1px solid black;
          &:focus{
            border-bottom: 3px solid #DB55EB;
            background: rgba(0, 0, 0, 0);
          }
        }
      }
      .reg-btn{
        // @include outline(0);    
        display: inline-flex;
        justify-content: center;
        align-items: center;     
        transition: background-color 0.1s ease 0s;
        border: 0px;
        border-radius: 2px;
        line-height: 1;
        overflow: hidden;
        user-select: none;
        vertical-align: top;
        font-size: 16px;
        padding: 0px 16px;
        height: 40px;
        color: #fff;    
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px;
        background-color: #DB55EB;
        text-decoration: none;

        font-family: 'LatoMedium';
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        /* identical to box height */

        letter-spacing: 0.015em;
        text-transform: capitalize;

        color: #000000;
        cursor: pointer;
        &:hover,&:focus{
          background-color: #DB55EB;
        }
      }
      .reg-logo{
        margin: 0 auto 24px;
      }
      .reg-tab{
        display: flex;
        margin-bottom: 24px;
        .tab-btn{
          display: inline-flex;
          justify-content: center;
          align-items: center;     
          cursor: pointer;
          position: relative;
          flex: 1 1 auto;
          width: 50%;
          font-size: 14px;
          color: #DB55EB;
          padding: 10px 5px;
        //   border-bottom: 1px solid rgb(226, 226, 226);
          text-decoration: none;
          user-select: none;
          &.active{
            cursor: auto;
            &:after{
              position: absolute;
              content: " ";          
              bottom: 0px;
              left: 0px;
              width: 100%;
              height: 4px;
              background: rgb(226, 226, 226);
            }
          }
        }
      }
      .back-btn{
        display: inline-flex;
        justify-content: center;
        align-items: center;     
        cursor: pointer;
        line-height: 1;
        user-select: none;
        font-size: 16px;
        padding: 0px 16px;
        height: 40px;
        color: rgb(13, 110, 241);
        display: inline-flex;
        width: 100%;
        background-color: transparent;
        text-decoration: none;
        border: none;
        box-shadow: none;
        .back-icon{
          margin-right: 8px;
          fill: rgb(13, 110, 241);
        }    
      }
      .reg-bottom{
        padding-top: 24px;
        font-size: 11px;    
        max-width: 320px;
        margin: 0 auto;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        line-height: 13px;
        /* identical to box height */
        letter-spacing: 0.015em;
        text-decoration-line: underline;
        text-transform: capitalize;
        cursor: pointer;
        .text-link{
            color: #ffffff;
        }
      }
      .bottom-text{
        color: #94B3FC;
      }
      .help-link{
        color :#ffffff;
        text-decoration: none;
        
      }
}
.home-submenu{
    margin: 40px 75px 0px 75px;
    ul {
        list-style-type: none;
        display: flex;
    }
     ul li  { 
        font-family: 'LatoLight';
        font-style: normal;
        font-size: 19px;
        margin: 0px 20px;
        font-weight: 500;
    }
    ul li :hover{
        color:green;
    }
}
.stoxdoc-innerpart { 
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width:99%;
    padding: 60px 15px 0;
}

@media(max-width:1023px){
    .stoxdoc-innerpart {
        padding: 60px 10px 0;
    }    
}

.watchlist-dropdown-container{
    width: 180px;
        // position: relative;
        // display: inline-block;

    select {
        width: 60px;
        font-weight: bolder;
        word-spacing: 5px;
        letter-spacing: 2px;
        $bg-color: #94B3FC;
        $text-color: #ffffff;
        appearance: none; // using -prefix-free https://leaverou.github.io/prefixfree/
        background: {
        color: $bg-color;
        image: url("../../Assets/Images/down.png");
        position: right 10px top 14px;
        padding-right:5px ;
        repeat: no-repeat;
        }
        border: {
        color: mix($bg-color, black, 80%);
        radius: .2em;
        style: solid;
        width: 1px;
        right-color: mix($bg-color, black, 60%);
        bottom-color: mix($bg-color, black, 60%);
        }
        color: $text-color;
        padding: 10px 20px 10px 10px; 
        width: 100%;
    }
    select::-ms-expand {
      display: none;
    }      
}
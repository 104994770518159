.create-portfolio-button-container{
    margin-left: 24px;
    .create-portfolio-button{
        // width: 130px;
        // height: 32px;
        padding: 7px;
        background: linear-gradient(90.94deg, #59F1D8 0.81%, #2AF270 83.74%);
        border-radius: 2px;
        border-color: #59F1D8;
        outline: none;
        font-family: 'LatoMedium';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.015em;
        text-transform: capitalize;
        color: #000000;
    }
}
.tableheader-container {
    border-left: 1px solid #ffffff;
    height: 50px;
    margin: 18px 30px;
    .tableheader-title{
        font-family: 'LatoLight';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0.015em;
        text-transform: capitalize;
        color: #FFFFFF;
        border-bottom: 1px solid #ffffff;
        margin-left: 10px;
        .days-drop-down{
            float: right;
        }
    }
}


@media(max-width:1199px) {
    .tableheader-container .tableheader-title { 
        font-size: 16px;
    }
}

@media(max-width:991px) { 
    .tableheader-container .tableheader-title .days-drop-down button{
        font-size: 16px !important;
        margin-right: 0px !important;
               
    }    
    .tableheader-container .tableheader-title .days-drop-down button + button{
        margin-left: 25px !important; 
    }
}

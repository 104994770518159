.left-header-container {
    .left-header{
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background: #051C55;
        display: flex;
        flex-direction: column;
        // background-image: url('../../Assets/Images/left.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
        .left-logo{
            display: flex;
            justify-content: center;
            margin-top: 30px;
            img{ 
                max-width: 180px;
            }
        }
        .main-list{
            display: flex;
            flex-direction: column;
            width: max-content;
            align-self: center;
            margin-top: 30px;
            .list{
                border-left: 5px dotted #94B3FC;
                margin: 0;
               div{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom:30px;
                    p { color: white; } 
                    .home-dropdown{
                        float: right;
                        cursor: pointer;
                   }
               } 
                .list-title{
                    margin: 0;
                    color: #ffffff;
                    letter-spacing: 0.015em;
                    text-transform: capitalize;
                    font-family: 'LatoSemibold';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    cursor: pointer;
                    margin-right: 30px;
                    .icon{
                        // position: relative;
                        margin-left: -11px;
                        margin-right: 10px;
                        height: fit-content;
                        cursor: pointer;
                    }
                }
                .text-color{
                    color: #c4c4c4;
                }
                .home-color{
                    color: #C4C4C4;
                }
                .main-sub-list{
                    display: flex;
                    flex-direction: row;
                    padding: 0;
                    justify-content: flex-start;                    
                }
                .main-active{
                    border: 2px #ffffff solid;
                    border-radius: 50%;
                    margin-left: -10px !important;
                    height: 14px !important;
                    width: 14px !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .main-sub-active{
                    border: 4px #ffffff solid;
                    border-radius: 50%;
                    height: 0;
                }
                .main-inactive{
                    border: 4px #C2C2C2 solid;
                    border-radius: 50%;
                    margin-left: -7px;
                }
                .sub-list{
                    font-family: 'LatoMedium';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: 0.015em;
                    text-transform: capitalize;
                    color: #ffffff;
                    cursor: pointer;
                    margin-left: 15px;
                }
            }
        }
        
    }
    .left-header-zindex{
        z-index: -1
    }
}
@media( max-width:1440px){
    .main-outer .left-header-container { width: 230px; }
    .main-outer .right-header-container { width: calc(100% - 230px); margin-left: 230px; }  
    .right-header-container .nav-bar {  width: calc(100% - 230px);  }
}

@media( max-width:991px){
    .left-header-container .left-header .main-list .list .list-title {
        margin-right: 10px;
        font-size: 16px;
    }
    .main-outer .left-header-container{
        width: 210px;
    }
    .main-outer .right-header-container{
        width: calc(100% - 210px);
        margin-left: 210px;
    }
    .right-header-container .nav-bar{
        width: calc(100% - 210px);
    }    
}


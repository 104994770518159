.backtesting-table-container{
    border: 1px solid #051C55;
    font-family: 'LatoLight';
    font-style: normal;
    letter-spacing: 0.015em;
    text-transform: capitalize;
    margin: 70px 0 0;
    width: 100%;
    background-color: #051C55;
    .backtesting-table{
        // width: 1337px;
        // height: 490px;
        .ag-header-row-column-group{
            // background-color: #708DD2;
            color: #051C55;
        }
        .ag-header-row-column{
            background-color:#708DD2;
        }
        .ag-theme-alpine .ag-root-wrapper{
            border:none;
        }
        .ag-ltr{
            background-color: #051C55;
        }
        .ag-row{
            background-color: #051C55;
            color: white;
            border: none;
        }
        .ag-cell{
            width: 0px;
        }
        .ag-theme-alpine {
            padding: 0;
            box-sizing: inherit;
            font-family: 'LatoMedium';
            font-size: 15px !important;
            border: none;
            box-sizing: content-box;
            // height: 270px;
        }
        .ag-header-cell-text{
            color: #051C55;
            // margin-left: 20px;
            // background-color: #0D1013;
        }
        .ag-input-field-input{
            background-color: #4f72b0a1;
        }
        .ag-header-group-text{
            margin: auto;
        }
        .ag-icon-grip{
            color: black;
        }
        .ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group{
            padding-right: 0px;
        }
        .ag-header-group-cell-label, .ag-header-cell-label{
            // overflow: hidden;
        }
        .ag-cell-label-container{
            // width: fit-content;
        }
        .ag-theme-alpine .ag-ltr .ag-row-drag, .ag-theme-alpine .ag-ltr .ag-selection-checkbox, .ag-theme-alpine .ag-ltr .ag-group-expanded, .ag-theme-alpine .ag-ltr .ag-group-contracted{
            margin-right: 0px;
        }
        .ag-cell >.ag-cell-auto-height >.ag-cell-not-inline-editing >.ag-cell-focus>.ag-cell-wrapper>.ag-cell-value:nth-child(1){
            padding-right: 9px;
            // left: 90px;
        }
        .ag-header-row-column{
            width: 81405px !important;
            // background-color: #0D1013;
        }
        .ag-header-row-column-group{
            width: 11405px !important;
            // background-color: #0D1013;
        }
        .transaction-type:nth-child(2){
            background: blanchedalmond;
        }
        input::-webkit-calendar-picker-indicator {
            cursor: pointer;
            padding: 0;
            margin: 0;
        }
        .ag-cell-value{
            overflow: hidden !important;
        }
        .ag-pinned-left-cols-container{
            height: 218px !important;
            // background-color: #708DD2 !important;
        }
    }
    .lockScreen img{
        width: 100%;
        height: 500px;
    }
    .ag-theme-alpine .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell){
        border: none;
    }
    // overflow-y: scroll;
//     table th{
//         // position: sticky;
//         // top: 0;
//     }
//     table{
//         border-collapse: collapse;
//         border: none;
//         width: 100%;
//     }
//     table, th, td {
//         border: 1px solid black;
//     }

//     table td:nth-child(1){
//         color: #0FC9FC;
//         font-size: 15px;
//         line-height: 18px; 
//         width: 2000px;
//         background-color: #0D1013;
//     }
    
//     table td{
//         font-size: 14px;
//         line-height: 17px;
//         color: #FFFFFF;
//         text-align: center;
//     }
//     table th {
//         background-color: #0D1013;
//         font-size: 18px;
//         line-height: 22px;
//         color: #27ED82;
//         height: 32px;
//         width: 1335px;
//         border: 1px solid #0D1013;
//     }
// }
// .backtesting-table::-webkit-scrollbar{
//     width:0px;
// }
// .backtesting-table::-webkit-scrollbar-track{
//     background: chartreuse;
// }
// .backtesting-table::-webkit-slider-thumb{
// }
// .backtesting-table::-webkit-slider-thumb:hover{
//     background-color: yellow;
// }
}

.home-table-container {
    border: 1px solid #051C55;
    background: #051C55;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // border-radius: 15px;
    font-family: 'LatoLight';
    font-style: normal;
    letter-spacing: 0.015em;
    text-transform: capitalize;
    margin-top: 70px;
    width: 100%; 
    .header_nav {
        height:20px;
        width:20px;
        border-radius:50%;
        border:1px solid white;
        margin: 10px;
        -moz-box-shadow: inset 0 0 6px #000000;
        -webkit-box-shadow: inset 0 0 6px #000000;
        box-shadow: inset 0 0 6px #000000;
    }
    label{
        font-size: 16px;
        font-weight: bold;
    }
.home-table{
    .ag-header-row-column-group{
         background: #051C55;
        color: #27ED82
    }
    .ag-header-row-column{
         background: #051C55;
    }
    .ag-ltr{
         background: #051C55;
    }
    .ag-row{
         background: #051C55;
        color: white;
        border: none;
    }
    .ag-cell{
        width: 0px;
    }
    .ag-theme-alpine {
        padding: 0;
        box-sizing: inherit;
        font-family: 'LatoMedium';
        font-size: 15px !important;
        border: none;
        box-sizing: content-box;
        // height: auto;
    }
    .ag-theme-alpine .ag-root-wrapper{
        border:none;
    }
    .ag-header-cell-text{
        color:#00040c;
        // margin-left: 20px;
        background: #94B3FC;
    }
    .ag-input-field-input{
        background-color: #4f72b0a1;
    }
    .ag-header-group-text{
        margin: auto;
    }
    .ag-icon-grip{
        color: black;
    }
    .ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group{
        padding-right: 0px;
    }
    .ag-header-group-cell-label, .ag-header-cell-label{
        overflow:initial;
    }
    .ag-cell-label-container{
        // width: fit-content;
    }
    .ag-theme-alpine .ag-ltr .ag-row-drag, .ag-theme-alpine .ag-ltr .ag-selection-checkbox, .ag-theme-alpine .ag-ltr .ag-group-expanded, .ag-theme-alpine .ag-ltr .ag-group-contracted{
        margin-right: 0px;
    }
    .ag-cell >.ag-cell-auto-height >.ag-cell-not-inline-editing >.ag-cell-focus>.ag-cell-wrapper>.ag-cell-value:nth-child(1){
        padding-right: 9px;
        // left: 90px;
    }
    .ag-header-row-column{
        // width: 1405px !important;
        background: #94B3FC;
    }
    .ag-header-row-column-group{
        // width: 1405px !important;
        background: #94B3FC;
    }
    .ag-row-odd{
        background-color: rgba(140, 172, 244, 0.3);;
    }
    .transaction-type:nth-child(2){
        background: blanchedalmond;
    }
    input::-webkit-calendar-picker-indicator {
        cursor: pointer;
        padding: 0;
        margin: 0;
    }
    .ag-cell-value{
        overflow: hidden !important;
    }
    
}
}

.fixed-income .ag-header-cell { width: 10%;  }

@media( max-width:1199px){ 
    .home-table-container { margin-top: 50px; } 
}

@media( max-width:991px){ 
.home-table-container .header_nav{
    height: 15px;
    width: 10px;
    margin:10px;
}
}

    


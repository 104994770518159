.stripeContainer{
  width: 100%;
  margin:0px 0px 0px 0;
  color: #fff;
} 

.paymentCard-container{
    font-family: 'LatoLight';
    font-style: normal;
    letter-spacing: 0.015em;
    text-transform: capitalize;
    width: 100%; 
    table{
      th {
        width: 20%;
      }
    }
}

/* pricing table */
  
  .table {
    display: flex;
    flex-wrap: wrap;
    border-radius: 2px;
    background-color: #051C55;
  }
  
  .column {
    // margin: 20px;
    // padding: 10px;
    position: relative;
    min-height: 249px;
    width: 100%;
    // width: 200px;
    text-align: center;
    transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    transform-style: preserve-3d;
  }
  
  .column-1 .column-front,
  .column-1 .column-back {
    border:1px solid #708DD2;
    // border-bottom: none;
  }
  .light-blue{
    background-color: #3D4E79;
  }
  .blue{
    background-color: #051C55;
  }

  tbody tr td:nth-child(1){
    padding-left:20px;
  }
  tbody tr td:nth-child(2) {
    text-align: center;
    padding-left:20px ;
    border:1px solid #708DD2;
    border-top: none;
    border-bottom: none;
  }
  tbody tr td:nth-child(3) {
    text-align: center;
    border:1px solid #708DD2;
    border-top: none;
    border-bottom: none;
  }
  tbody tr td:nth-child(4) {
    text-align: center;
    border:1px solid #708DD2;
    border-top: none;
    border-bottom: none;
  }
  tbody tr td:nth-child(5) {
    text-align: center;
    border:1px solid #708DD2;
    border-top: none;
     border-bottom: none;
  }
  tbody tr{
    line-height: 50px;
    padding: 10px;
  }
    
  .column-2 .column-front,
  .column-2 .column-back {
    background-color: lightgreen;
  }
  
  .column-3 .column-front,
  .column-3 .column-back {
    background-color: orange;
  }
  
  .column-front,
  .column-back {
    position: absolute;
    border: 1px solid grey;    
    width: 100%;
    height: 101%;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .flip {
    transform: rotateY(180deg);
  }
  
  .column-back {
    transform: rotateY(180deg);
  }
  
  /* switch */
  
  .yc-form {
    display: flex;
    margin-left: 0;
    padding: 10px;
    max-width: 100%;
    justify-content: flex-end;
  }
  
  .yc-form input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
  }
  
  .yc-form label {
    font-size: 1em;
    text-align: center;
    padding: 0.5em 2em;
    background-color: #051C55;
    border: 1px solid grey;
  }
  
  .yc-form label:hover {
    cursor: pointer;
  }
  
//   .yc-form input#r-monthly:checked+label {
//     background-color: grey;
//     color: white;
//   }
  
//   .yc-form input#r-yearly:checked+label {
//     background-color: grey;
//     color: white;
//   }
  
  .yc-form label:first-of-type {
    border-radius: 25px 0 0 25px;
  }
  
  .yc-form label:last-of-type {
    border-radius: 0 25px 25px 0;
  }

  .plan-title{
      color: #FFDF00;
  }

  .subscribe{
      background-color: #708DD2;
      border-color: #708DD2;
      border-radius: 5px;
      color: black;
      font-weight: bold;
      padding: 5px 10px;
      margin-bottom: 20px;
      width: auto;
  }

  .mo {
      font-size: 0.67rem;
      color:white;
      
  }
  .year {
    font-size: 0.67rem;
    color:white;
}

.column-front p {
    font-weight: bold;
    // font-size: 2.67rem;
    margin: 0;
    padding-bottom: 20px;
}

.plan-property span{
  text-align: start;
  font-size: 18px;
  color: white;
  font-weight: bold;
  line-height: 30px;
}
.plan-property span::before{
  content: '\f006';
  font-family: 'FontAwesome';
  // display: "inline-block";
  float: left;
  margin-left: -1.5em;
  color: #d7e2eb;
}
.check-img{
  width: 32px;
  height: 32px;
}

@media only screen and  (max-width: 1800px) and (min-width: 1600px) {  
  .yc-form{
    margin-left:70% ;
  }
}

@media only screen and  (max-width: 1600px) and (min-width: 1400px) {  
  .column{
    height: 230px;
  }
  
}
@media(max-width: 992px) {
  tbody tr td:nth-child(2){

    font-size: 16px;
    padding-left: 10px;
  }
  tbody tr td:nth-child(1){
    padding-left: 10px;
    font-size: 16px;
  }
  .column .column-front h2{
    font-size: 22px;
  }
}


.watchlistTableData-table-container{
    border: 0.1px solid #051C55;
    font-family: 'LatoMedium';
    font-style: normal;
    letter-spacing: 0.015em;
    text-transform: capitalize;
    margin: 70px 0 0;
    width: 100%;
    background-color: #051C55;
.watchlistTableData-table  {
    .ag-header-row-column-group{
        background-color: #708DD2;
        color: #051C55;
    }
    .ag-header-row-column{
        background-color: #051C55;
    }
   .ag-row-even{
    background: rgba(140, 172, 244, 0.3);
   } 
    .ag-row-odd{
        background: rgba(140, 172, 244, 0.3);
    }
    .ag-ltr{
        // min-height:750px;
        min-height: auto;
        background-color: #051C55;
    }
    .ag-row{
        background-color: #051C55;
        color: white;
        border: none;
    }
    .ag-cell{
        width: 0px;
    }
    .ag-theme-alpine {
        padding: 0;
        box-sizing: inherit;
        font-family: 'LatoMedium';
        font-size: 15px !important;
        border: none;
        box-sizing: content-box; 
    }
    .ag-theme-alpine .ag-root-wrapper{
        border:none;
    }
    .ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell{
        border:none;
    }
    .ag-cell .ag-cell-not-inline-editing .ag-cell-auto-height{
        left: 100px;
    }
    .ag-header-cell-text{
        color: #0FC9FC;
        margin-left: 20px;
    }
    .ag-input-field-input{
        background-color: cornflowerblue;
    }
    .ag-header-group-text{
        margin: auto;
    }
    .ag-icon-grip{
        color: #051C55;
    }
    .ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group{
        padding-right: 0px;
    }
    .ag-header-group-cell-label, .ag-header-cell-label{
        overflow:initial;
    }
    .ag-cell-label-container{
        width: fit-content;
    }
    .ag-theme-alpine .ag-ltr .ag-row-drag, .ag-theme-alpine .ag-ltr .ag-selection-checkbox, .ag-theme-alpine .ag-ltr .ag-group-expanded, .ag-theme-alpine .ag-ltr .ag-group-contracted{
        margin-right: 0px;
    }
    .ag-header-row-column >div:nth-child(5)>.ag-cell-label-container>.ag-header-cell-label>.ag-header-cell-text{
        color: #FE5B5B;
    }
    .ag-header-row-column >div:nth-child(6)>.ag-cell-label-container>.ag-header-cell-label>.ag-header-cell-text{
        color: #48FF65;
    }
    .ag-row>div:nth-child(3)>.ag-cell-wrapper, .ag-row>div:nth-child(4)>.ag-cell-wrapper, .ag-row>div:nth-child(5)>.ag-cell-wrapper,
    .ag-row>div:nth-child(6)>.ag-cell-wrapper, .ag-row>div:nth-child(7)>.ag-cell-wrapper, .ag-row>div:nth-child(8)>.ag-cell-wrapper,
    .ag-row>div:nth-child(9)>.ag-cell-wrapper, .ag-row>div:nth-child(10)>.ag-cell-wrapper, .ag-row>div:nth-child(11)>.ag-cell-wrapper,
    .ag-row>div:nth-child(12)>.ag-cell-wrapper, .ag-row>div:nth-child(13)>.ag-cell-wrapper, .ag-row>div:nth-child(14)>.ag-cell-wrapper, 
    .ag-row>div:nth-child(15)>.ag-cell-wrapper, .ag-row>div:nth-child(16)>.ag-cell-wrapper, .ag-row>div:nth-child(17)>.ag-cell-wrapper,
     .ag-row>div:nth-child(18)>.ag-cell-wrapper, .ag-row>div:nth-child(19){
        justify-content: center;
    }
    // .ag-row>div >.ag-cell-wrapper{
    //     justify-content: center;
    // }
   
    // .ag-theme-alpine .ag-icon-grip:before{
    //     content: "" !important;
    // }
    // .ag-row :nth-child(1)>.ag-cell-wrapper>.ag-theme-alpine >.ag-icon-grip :before{
    //     content: "\f116" !important;
    // }
    .ag-cell >.ag-cell-auto-height >.ag-cell-not-inline-editing >.ag-cell-focus>.ag-cell-wrapper>.ag-cell-value:nth-child(1){
        padding-right: 9px;
    }
    // .ag-header-container>div:nth-child(1)>div:nth-child(1){
    //     padding: 0;
    //     cursor: pointer;
        // background-image : url("../../Assets/Images/editWatchlis.svg");
    //     background-repeat: no-repeat;
    //     background-position-x: 6px;
    //     background-position-y: center;
        
    // }
    .ag-header-row-column{
        // width: 1905px !important;
    }
    .ag-header-row-column-group{
        // width: 1905px !important;
    }
    .ag-cell-value{
        overflow: hidden !important;
    }
    .ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group{
        border: 1px solid transparent;
        line-height: 40px;
        padding-left: 0px !important;
        padding-right: 0px !important; 
        -webkit-font-smoothing: subpixel-antialiased;
        height: fit-content;
    }
    }
}

.efficientfrontierData-table-container{
    border: 1px solid #051C55;;
    font-family: 'LatoMedium';
    font-style: normal;
    letter-spacing: 0.015em;
    text-transform: capitalize;
    margin: 70px 0 0;
    width: 40%;
    background-color: #051C55;
    .efficientfrontierData-table{
        width:  100%;
        .ag-header-row-column-group{
            background-color: #051C55;
            color: #94B3FC;
        }
        .ag-header-row-column{
            background-color:#051C55;
        }
        .ag-theme-alpine .ag-root-wrapper{
            border:none;
        }
        .ag-ltr{
            background-color: #051C55;
        }
        .ag-row{
            background-color: #051C55;
            color: white;
            border: none;
        }
        .ag-cell{
            width: 0px;
        }
        .ag-theme-alpine {
            padding: 0;
            box-sizing: inherit;
            font-family: 'LatoMedium';
            font-size: 14.5px !important;
            border: none;
            box-sizing: content-box;
            height: 490px;
        }
        .ag-header-cell-text{
            color: #94B3FC;
            // margin-left: 20px;
            background-color: #051C55;
        }
        .ag-input-field-input{
            background-color: #051C55;
        }
        .ag-header-group-text{
            margin: auto;
        }
        .ag-icon-grip{
            color: #051C55;
        }
        .ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group{
            padding-right: 0px;
        }
        .ag-header-group-cell-label, .ag-header-cell-label{
            overflow:initial;
        }
        .ag-cell-label-container{
            // width: fit-content;
        }
        .ag-theme-alpine .ag-ltr .ag-row-drag, .ag-theme-alpine .ag-ltr .ag-selection-checkbox, .ag-theme-alpine .ag-ltr .ag-group-expanded, .ag-theme-alpine .ag-ltr .ag-group-contracted{
            margin-right: 0px;
        }
        .ag-cell >.ag-cell-auto-height >.ag-cell-not-inline-editing >.ag-cell-focus>.ag-cell-wrapper>.ag-cell-value:nth-child(1){
            padding-right: 9px;
            // left: 90px;
        }

        .ag-row-odd{
            // background-color: #161616;
        }
        .transaction-type:nth-child(2){
            background: blanchedalmond;
        }
    
        input::-webkit-calendar-picker-indicator {
            cursor: pointer;
            padding: 0;
            margin: 0;
        }
        .ag-cell-value{
            overflow: hidden !important;
        }
        .ag-focus-managed{
            width: 100% !important;
        }
    }
    .efficient-date{
        background-color: #DB55EB;
        width: 135px;
        height: 28px;
        border-color: #051C55;
        vertical-align: top;
    }
    .lockScreen img{
        width: 100%;
        height: 508px;
    }
}

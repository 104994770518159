.netassetvalue-container{
    border: 1px solid #051C55;
    font-family: 'LatoLight';
    font-style: normal;
    letter-spacing: 0.015em;
    text-transform: capitalize;
    margin: 70px 0 0;
    width: 100%;
    background-color: #051C55;
    .net-asset-value-days{
        display: flex;
        justify-content: flex-end;
        // margin: 0px 12px 12px;
        h4{
            margin-left: 15px;
            font-family: 'LatoLight';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.015em;
            text-transform: capitalize;
            color: #FFFFFF;
        }
    }
    .lockScreen img{
        width: 100%;
        height: 500px;
    }
}
.watchlist-dropdown-container mr-10{
    select{
        width: 60px;
    }
}

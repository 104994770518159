.create-portfolio-modal-container{
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    .create-portfolio-modal{

        .create-portfolio-modal-btn{
            width: 48px;
            height: 28px;
            background: #94B3FC;
            border-radius: 1px;
            outline: none;
            border-color: #94B3FC;
            float: right;
            margin-bottom: 5px;
        }
    }
    .create-portfolio-modal-table{
        .create-portfolio-table{
            border-collapse: collapse;
            width: 100%;
        }
        .create-portfolio-table td, .create-portfolio-table th {
            border: 1px solid #4F4F4F;
            // padding: 8px;
        }
        .create-portfolio-table{
            font-family: 'LatoLight';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.015em;
            text-transform: capitalize;
            color: #94B3FC;
        }
        .create-portfolio-data{
            // text-align: center;
            th{
                padding: 15px 0px;
            }
            td{
                background: #ffffff42;
            }
            input{
                // width: 232px;
                background: #80808000;
                font-size: 15px;
                height: 25px;
                outline: none;
                border-color: #80808000;
                color: white;
                border: none;
                font-weight: bold;
                text-align: center;
            }
        }
    }
    .create-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        .btn{
            // margin-top: 10%;
            width: 77.83px;
            height: 32.09px;
            left: 978.17px;
            top: 349.17px;
            background:#94B3FC;
            border-radius: 1px;
            outline: none;
            color:#051C55;
            font-weight:600;
            border-color: #94B3FC;
            float: right;
        }
    }
}
.broker-div{
    height: 120px;
    // border: 2px solid white;
    padding-top:20px;
    margin: 20px 0px;
    // justify-content: space-between;
    // overflow-y: scroll;
}
.broker-btn-div{
    width: 50%;
    display:flex;
    justify-content: space-between;
}
.broker-array-div{
    display: flex;
    width: 65%;
    padding-top:20px;
    justify-content: space-between;
}
button{
    cursor: pointer;
}
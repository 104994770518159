.liquidityvolatility-table-container{
    border: 1px solid #051C55;
    font-family: 'LatoMedium';
    font-style: normal;
    letter-spacing: 0.015em;
    text-transform: capitalize;
    // margin: 50px 125px;
    margin: 70px 0 0;
    // width: 617.56px;
    // height: 514.6px;
    width: 45%;
    .liquidityvolatility-table{
        // width: 1337px;
        // height: 200px;
         .ag-header-row-column-group{
            background-color: #051C55;
            color: #94B3FC
        }
        .ag-header-row-column{
            // background-color:#708DD2;
        }
        .ag-ltr{
            background-color: #051C55;
        }
        .ag-row{
            background-color: #051C55;
            color: white;
            border: none;
        }
        .ag-cell{
            width: 0px;
        }
        .ag-theme-alpine {
            padding: 0;
            box-sizing: inherit;
            font-family: 'LatoMedium';
            font-size: 15px !important;
            border: none;
            box-sizing: content-box;
            // height: 490px;
        }
        .ag-header-cell-text{
            color: #ffffff;
            margin-left: 70px;
            background-color: #051C55;
            text-align: center;
        }
        .ag-input-field-input{
            background-color: #4f72b0a1;
        }
        .ag-header-group-text{
            margin: auto;
        }
        .ag-icon-grip{
            color: black;
        }
        .ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group{
            padding-right: 0px;
        }
        .ag-header-group-cell-label, .ag-header-cell-label{
            overflow:initial;
        }
        // .ag-cell-label-container{
        //     // width: fit-content;
        // }
        .ag-theme-alpine .ag-ltr .ag-row-drag, .ag-theme-alpine .ag-ltr .ag-selection-checkbox, .ag-theme-alpine .ag-ltr .ag-group-expanded, .ag-theme-alpine .ag-ltr .ag-group-contracted{
            margin-right: 0px;
        }
        .ag-cell >.ag-cell-auto-height >.ag-cell-not-inline-editing >.ag-cell-focus>.ag-cell-wrapper>.ag-cell-value:nth-child(1){
            padding-right: 9px;
            // left: 90px;
        }
        .ag-body-viewport.ag-layout-normal{
            margin-top: 7px;
        }
        
        .transaction-type:nth-child(2){
            background: blanchedalmond;
        }
        input::-webkit-calendar-picker-indicator {
            cursor: pointer;
            padding: 0;
            margin: 0;
        }
        .ag-cell-value{
            overflow: hidden !important;
        }
        // .heading-select{
        //     background: blue;
    }
    .lockScreen img{
        width: 100%;
        height: 400px;
    }
}
@media(max-width:1199px){
    // .liquidityvolatility-table-container {
    //     width: 100% !important;
    // }
}

@media(max-width:991px){
    .liquidityvolatility-table-container{
        width: 100%;
    }
}
.transactionData-table-container{
    border: 1px solid #051C55;
    font-family: 'LatoMedium';
    font-style: normal;
    letter-spacing: 0.015em;
    text-transform: capitalize;
    margin: 70px 0 0;
    width: 100%;
    background-color: #051C55;
.transactionData-table {
         .ag-header-row-column-group{
            background-color: #051C55;
            color: #94B3FC
        }
        .ag-header-row-column{
            background-color:#051C55;
        }
        .ag-ltr{
            background-color: #051C55;
        }
        .ag-theme-alpine .ag-root-wrapper{
            border:none;
        }
        .ag-row{
            background-color: #051C55;
            color: white;
            border: none; 
        }
        .ag-cell{
            width: 0px;
        }
        .ag-theme-alpine {
            padding: 0;
            box-sizing: inherit;
            font-family: 'LatoMedium';
            font-size: 15px !important;
            border: none;
            box-sizing: content-box;
        }        
        .ag-header-cell-text{
            color: #94B3FC;
            margin-left: 40px;
            background-color: #051C55;
        }
        .ag-input-field-input{
            background-color: #4f72b0a1;
        }
        .ag-header-group-text{
            margin: auto;
        }
        .ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group{
            padding-right: 0px;
        }
        .ag-header-group-cell-label, .ag-header-cell-label{
            overflow:initial;
        }
        .ag-cell-label-container{
            width: fit-content;
        }
        
        .ag-cell >.ag-cell-auto-height >.ag-cell-not-inline-editing >.ag-cell-focus>.ag-cell-wrapper>.ag-cell-value:nth-child(1){
            padding-right: 9px;
        }
        .ag-header-row-column{
            // width: 1405px !important;
        }
        .ag-header-row-column-group{
            // width: 1405px !important;
            background-color: #051C55;
        }
        .ag-row-odd{
            background-color: rgba(140, 172, 244, 0.3);
        }
        .transaction-type:nth-child(2){
            background: blanchedalmond;
        }
        input::-webkit-calendar-picker-indicator {
            cursor: pointer;
            padding: 0;
            margin: 0;
        }
          .ag-cell-value{
            overflow: hidden !important;
        }
        .ag-theme-alpine [class^='ag-'], .ag-theme-alpine [class^='ag-']:focus, .ag-theme-alpine [class^='ag-']:after, .ag-theme-alpine [class^='ag-']:before{
            color: white;
        }
    }
}
.transactionData-table{
    overflow-x: scroll;
}

@media(max-width:991px){
    .transactionData-table .ag-theme-alpine .ag-header-cell {
        width: 145px !important;
    }
    .transactionData-table .ag-theme-alpine .ag-cell {
        width: 145px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        
    }
    .transactionData-table .ag-center-cols-container,
    .transactionData-table .ag-header-container{ 
        width: 1170px !important;

    }
}


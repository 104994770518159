.plaid-container{
    width: 100%;
    max-width: 95%;
    margin: 50px auto;
    padding: 0 10px;
    // .plaid-btn{
    //     border: 0;
    //     padding: 10px 40px;
    //     color:#051C55;
    //     border-color: #94B3FC;
    //     border-radius: 5px;
    //     font-weight: bold;
    //     font-size: 16px;
    //     margin: 15px 0px;
    //     cursor: pointer;
    // }
    // .flexbox .Overlay.desktop{
    //     background-color: none !important;
    //     background: none !important;
    // }
    ::placeholder {
        color: white;
        }
}

@media only screen and  (max-width: 1800px) and (min-width: 1400px) {
    .plaid-container{
        width: 1050px;
    }
}
.container { 
    height: 200px;
    position: relative;
  }
  
  .vertical-center {
    // margin: 0;
    margin-left: 38px;
    position: absolute;
    top: -70%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    .plaid-btn{
        border: 0;
        padding: 10px 40px;
        color:#051C55;
        border-color: #94B3FC;
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        margin: 15px 0px;
        cursor: pointer;
    }
    .flexbox .Overlay.desktop{
        background-color: none !important;
        background: none !important;
    }
}
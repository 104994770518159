.card {
  background-color: white;
  padding: 10px 20px 11px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #afafaf;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}
.pay-button{
  padding: 0.7rem 0.7rem;
  width: 200px;
  margin: 7rem 10rem 0 10rem;
  color: black;
  font-weight: bold;
  font-size: medium;
  /* background-color: #556cd6; */
  background: linear-gradient(94.68deg, #59F1D8 -15.73%, #2AF270 92.38%);
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  transition: box-shadow 500ms;
  cursor: pointer;
}
.pay-button:disabled{
  background-color: #afafaf;
  box-shadow: none;
  cursor: default;
}
.pay-button:disabled:hover{
  box-shadow: none;
}
.pay-button:hover{
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
}

.cancel-button{
    padding: 0.7rem 0.7rem;
    width: 200px;
    margin: 7rem 10rem 0 10rem;
    color: black;
    font-weight: bold;
    font-size: medium;
    /* background-color: #556cd6; */
    background: linear-gradient(94.68deg, #59F1D8 -15.73%, #2AF270 92.38%);
    border: 0;
    border-radius: 5px;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    cursor: pointer;
}
.create-new-watchlist-input{
  width: 400px;
  height: 40px;
  border: 0.5px solid #94B3FC;
  box-sizing: border-box;
  border-radius: 2px;

  font-family: 'LatoLight';
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.015em;
  background: black;
  color: #77867A;
  padding: 10px 10px;
  display: flex;
  /* margin: auto; */
}
.mt-15{
  margin-top: 15px;
}
.align-items-center{
  display: flex;
  align-items: center;
}
.justify-content-beetween{
  display: flex;
  justify-content: space-between;
}
.coupon-process{
  position: absolute;
  top: -6px;
  left: -6px;
  z-index: 1;
  color:green !important 
}

/* The Modal (background) */

.modal {
  /* display: none; */
  position: fixed;
  z-index: 1;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #254354;
  background-color: rgba(37, 67, 84, 0.6);
  transform: translate(-50%, -50%);
}

/* Modal Content */

.modal-content {
  width: 60%;
  margin: 50px auto;
  border: 1px solid black;
  background: black;
  padding: 20px 0px;
  max-width: 100%;
  transform: translate(0, 0);
}

.delete-modal-content{
  width: 550px;
  height: 250px;
}

.modal-header {
  background-color: black;
  text-align: center;
}

.modal-header > div {
}

.modal-header > div h6 span{
  font-weight: bold;
}

/* The Close Button */
.close {
  color: #94B3FC;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-right: 10px;
}

.close:hover,
.close:focus {
  color: #94B3FC;
  text-decoration: none;
  cursor: pointer;
}

.header-title{
  font-family: 'LatoLight';
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.015em;
  // text-transform: capitalize;
  color: #94B3FC;
  border-bottom: 1px solid #94B3FC;
  margin: 24px 35px;
  padding-bottom: 30px;
}
@media only screen and  (max-width: 1600px) and (min-width: 1400px) {
 .modal{
   padding-left: 0;
   padding-top: 130px;
 } 
}
.dropbtn {
    background: linear-gradient(90.94deg, #59F1D8 0.81%, #2AF270 83.74%);
    border-radius: 3px;
    color: black;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    font-family: 'LatoRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    width: 200px;
    display: flex;
    justify-content: space-between;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .portfolio-dropdown-content {
    display: none;
    position: absolute;
    background-color: black;
    min-width: 115px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    font-family: 'LatoRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }
  
  .portfolio-dropdown-content a {
    color: #58F1D6;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-family: 'LatoRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    border-top: 0.5px solid #464B49;
    width: 170px;
    height: 22px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .portfolio-dropdown-content a:hover {color: black;background:  linear-gradient(90.94deg, #59F1D8 0.81%, #2AF270 83.74%);}
  
  .dropdown:hover .portfolio-dropdown-content {
    display: block;
    font-family: 'LatoRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }
  
  .dropdown:hover .dropbtn {
    background: linear-gradient(90.94deg, #59F1D8 0.81%, #2AF270 83.74%);
    border-radius: 3px;
  }
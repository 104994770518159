.main-navigation {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: auto;
}
a{
    cursor: pointer;
}
.main-navigation > ul {
  position: relative;
  display: flex;
  width: 100%;
  vertical-align: middle;
  height: 100%;
}

.main-navigation ul {
  font-weight: bold;
  list-style: none;
  margin: 0px 0px;
  padding: 0px 0px;
}

.main-navigation > ul > li {
  margin: 0px 20px 0 0;
  padding: 0px 0px;
  float: left;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.main-navigation > ul > li:before {
  position: absolute;
  height: 3px;
  width: 0;
  bottom: -1px;
  right: 0;
  // background: #43baff;
  content: "";
  display: block;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
}

.main-navigation > ul > li:hover:before,
.main-navigation > ul > li.current-menu-item:before,
.main-navigation > ul > li.current-menu-ancestor:before {
  left: 0;
  right: auto;
  width: 100%;
}

.main-navigation ul ul {
  float: none;
  margin: 0px 0px;
  padding: 0px 0px;
  background-color: #708DD2;
  box-shadow: 15px 15px 38px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 15px 15px 38px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 15px 15px 38px 0px rgba(0, 0, 0, 0.1);
}

.main-navigation ul > li > a {
  display: inline-block;
  // padding: 32px 0px;
  line-height: 35px;
  text-decoration: none;
  text-align: center;
  outline: none;
  color: #ffffff;
  width: 95px;
  font-family: 'LatoLight';
  background-color:#708DD2;
  white-space: nowrap;
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
.main-navigation ul > li > a:hover {
  color: #ffffff;
}

.main-navigation ul > li.menu-item-has-children > a {
  border-radius: 10px;
  padding: 0px 10px;
}

.main-navigation > ul > li.menu-item-has-children:last-child > a:after {
  right: 0px;
}

.main-navigation ul li li {
  display: block;
  position: relative;
  padding: 0px 30px;
}

.main-navigation ul li li a {
  font-size: 14px;
  line-height: 30px;
  font-weight: bold;
  color: white;
  text-align: left;
  display: block;
  font-family: 'LatoLight';
  // padding: 5px 0px 5px 0px;
  position: relative;
  text-decoration: none;
  outline: none;
  // text-transform: uppercase;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
}

.main-navigation ul li li a:before {
  position: absolute;
  // content: "//";
  top: 50%;
  left: 0;
  color:#ffffff;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  visibility: hidden;
  opacity: 0;
}

.main-navigation ul li li a:hover:before,
.main-navigation ul ul li.current-menu-item > a:before,
.main-navigation ul ul li.current-menu-ancestor > a:before {
  left: 0px;
  visibility: visible;
  opacity: 1;
}

.main-navigation ul li li a:hover,
.main-navigation ul ul li.current-menu-item > a,
.main-navigation ul ul li.current-menu-ancestor > a {
  color: #ffffff;
  padding-left: 12px;
}

.main-navigation ul > li.menu-item-has-children > a:after {
  position: absolute;
  right: 2px;
  top: 50%;
  margin-top: -4px;
  font-family: "Flaticon";
  content: "";
  font-size: 7px;
  line-height: 1;
  color: #ffffff;
  font-weight: 500;
  transform: rotate(90deg);
}

.main-navigation ul > li.menu-item-has-children > a:hover:after {
  color: #ffffff;
}

.main-navigation ul > li li.menu-item-has-children > a:after {
  position: absolute;
  content: "";
  font-family: Flaticon;
  top: 50%;
  right: 0;
  margin-top: -5px;
  font-size: 9px;
  color: #ffffff;
  transform: rotate(0deg);
}

.main-navigation ul li ul {
  width: 180px;
  white-space: nowrap;
  padding: 15px 0px;
  position: absolute;
  top: calc(100% + 1px);
  left: -40px;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  box-sizing: border-box;
}

.main-navigation ul li ul ul {
  left: 100%;
  top: -25px;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
}

.main-navigation ul li:hover > ul {
  visibility: visible;
  opacity: 1;
}

.main-navigation ul > li:hover > ul {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}
@media only screen and  (max-width: 1600px) and (min-width: 1400px) {
  .main-navigation ul > li > a{
      // width: 80px;
      overflow-wrap: break-word
  }
  .tag-line{
      // display: flex  !important;
      overflow: hidden;
  }
  .main-navigation > ul > li{
    margin: 0px 6px;
   }
   .navbar-container .navbar-list .bell-icon{
     margin-left: 3% !important;
   }
}
@media only screen and  (max-width: 1800px) and (min-width: 1600px) {
   .main-navigation > ul > li{
      margin:0px 8px;
   }
   .portfolio-drop-down{
     margin-right: 10px;
   }

}
.more-action-container{
    margin-left: 24px;
    margin-top: 15px;
    .more-action{
        // cursor: pointer;
        padding: 7px;
        background: linear-gradient(90.94deg, #59F1D8 0.81%, #2AF270 83.74%);
        border-radius: 2px;
        font-family: 'LatoRegular';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.015em;
        text-transform: capitalize;
        border-color: #2AF270;
        outline: none;
        color: #000000;
    }
}
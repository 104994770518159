.edit-transaction-modal-container{
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    .edit-transaction-modal{
        .edit-transaction-status{
            font-family: 'LatoLight';
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.015em;
            text-transform: capitalize;
            color: #79BDE4;
        }
        .edit-transaction-modal-btn{
            width: 48px;
            height: 28px;
            background: #94B3FC;
            border-radius: 1px;
            outline: none;
            color:#051C55;
            font-weight:600;
            border-color: #94B3FC;
            float: right;
            margin: 15px 0px;
        }
    }
    .edit-transaction-modal-table{
        .edit-transaction-table{
            border-collapse: collapse;
            width: 100%;

            tbody td{
                color: #1EF2F2;
            }
        }
        .edit-transaction-table td, .edit-transaction-table th {
            border: 1px solid #4F4F4F;
            padding: 8px;
        }
        .edit-transaction-table{
            font-family: 'LatoLight';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.015em;
            text-transform: capitalize;
            color: #94B3FC;
        }
        .action{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }
        .edit-data{
            text-align: center;
            background: #ffffff42;
        }
    }
    .create-btn{
        .btn{
            margin-top: 10%;
            width: 77.83px;
            height: 32.09px;
            left: 978.17px;
            top: 349.17px;
            background:#94B3FC;
            border-radius: 1px;
            outline: none;
            color:#051C55;
            font-weight:600;
            border-color: #94B3FC;
            float: right;
        }
    }
}